.avatar{
    display:inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow:hidden;
}

.avatar img{
    width: 100%;
    height: 100%;
}
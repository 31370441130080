@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #8d69f1;
  --highlight-color: #d13267;
  --bg-color: #f4f4f4;
}

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: #f4f4f4;
  background: var(--bg-color);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: #444;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: #8d69f1;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #8d69f1;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: #8d69f1;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}
.App {
  display: flex;
}
.App .container{
  flex-grow: 1;
  padding: 0 60px;
}
.avatar{
    display:inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow:hidden;
}

.avatar img{
    width: 100%;
    height: 100%;
}
.project-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 20px;
  }
  .project-list a {
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
  }
  .project-list h4 {
    font-size: 0.9em;
    color: var(--heading-color);
  }
  .project-list p {
    color: var(--text-color);
    font-size: 0.9em;
  }
  .project-list .assigned-to {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
  .project-list ul {
    margin: 10px 0;
    display: flex;
  }
  .project-list li {
    margin-right: 10px;
  }
  .project-list .avatar {
    width: 30px;
    height: 30px;
  }

.project-filter {
    margin: 30px auto;
  }
  .project-filter nav {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
  }
  .project-filter p {
    font-size: 0.9em;
    margin-right: 10px;
  }
  .project-filter button {
    background: transparent;
    border: 0;
    font-family: inherit;
    font-weight: bold;
    color: var(--text-color);
    cursor: pointer;
    border-right: 1px solid #e4e4e4;
    font-size: 0.9em;
  }
  .project-filter button:last-child {
    border: 0;
  }
  .project-filter button.active {
    color: var(--primary-color);
  } 

.create-form {
   max-width: 600px;
}
.project-details {
    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: start;
    grid-gap: 60px;
  }
  
  /* project summary */
  .project-summary {
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
  }
  .project-summary .due-date {
    margin: 10px 0;
    font-size: 0.9em;
    color: var(--title-color);
  }
  .project-summary .details {
    margin: 30px 0;
    color: var(--text-color);
    line-height: 1.8em;
    font-size: 0.9em;
  }
  .project-summary h4 {
    color: var(--text-color);
    font-size: 0.9em;
  }
  .project-summary .assigned-users {
    display: flex;
    margin-top: 20px;
  }
  .project-summary .assigned-users .avatar {
    margin-right: 10px;
  }
  .project-summary + .btn {
    margin-top: 20px;
  }
  
  /* project comments */
  .project-comments label {
    margin-bottom: 0px;
  }
  .project-comments textarea {
    min-height: 40px;
    font-size: 0.9em;
  }
  
  /* comment list */
  .project-comments h4 {
    color: var(--heading-color);
  }
  .project-comments li {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    margin-top: 20px;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: #fff;
  }
  .comment-author {
    display: flex;
    align-items: center;
    color: var(--title-color);
  }
  .comment-author .avatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .comment-date {
    color: var(--text-color);
    font-size: 0.9em;
    margin: 4px 0 10px;
  }
  .comment-content {
    color: var(--text-color);
    font-size: 0.6em;
  }
  
  /* comments scrollbar */
  .project-comments {
    max-height: 800px;
    overflow: auto;
    padding-right: 20px;
    /* firefox scrollbar style */
    scrollbar-width: 20px;
    scrollbar-color: #fff #e4e4e4;
  }
  /* Chrome, Edge, and Safari scrollbar styles */
  .project-comments::-webkit-scrollbar {
    width: 6px;
  }
  .project-comments::-webkit-scrollbar-track {
    background: #fff;
  }
  .project-comments::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid #e4e4e4;
  }
.auth-form {
    max-width: 360px;
    margin: 60px auto;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
    background: #fff;
  }

.navbar {
    width: 100%;
    padding:30px 0;
    box-sizing: border-box;
    margin-bottom: 80px;
}

.navbar ul {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
}

.navbar .logo {
    font-weight: bold;
    color: var(--heading-color);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-right: auto;
}

.navbar .logo img {
    margin-right: 10px;
    -webkit-filter:invert(25%);
            filter:invert(25%);
    width: 40px;
    margin-top: -8px;
}

.navbar a {
    color: #333;
    text-decoration: none;
    margin-right: 20px;
}

.sidebar {
    width: 300px;
    min-width: 300px;
    background: var(--primary-color);
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    color: #fff;
  }
  .sidebar-content {
    position: fixed;
    width: inherit;
  }
  .sidebar .user {
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    padding: 40px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .sidebar .links {
    margin-top: 80px;
    margin-left: 20px;
  }
  .sidebar .links li {
    margin-top: 10px;
  }
  .sidebar .links a {
    display: flex;
    padding: 10px;
    text-decoration: none;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
  }
  .sidebar .links img {
    margin-right: 10px;
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }
  .sidebar .links a.active {
    color: #555;
    background: var(--bg-color);
    border-radius: 20px 0 0 20px;
  }
  .sidebar .links .active img {
    -webkit-filter: invert(40%);
            filter: invert(40%);
  }
.user-list {
width: 250px;
min-width: 250px;
padding: 30px;
box-sizing:border-box;
color: var(--heading-color);

}

.user-list h2 {
text-align:right;
margin-bottom: 40px;
padding-bottom: 10px;
border-bottom: 1px solid #eee;
font-size: 1.2em;
}

.user-list .user-list-item {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px auto;
    
}

.user-list .avatar {
    
    margin-left: 10px;
    width: 40px;
    height: 40px;

}

/*Online users*/

.user-list .online-user {
    display:inline-block;
    margin-right:10px;
    width:12px;
    height: 12px;
    background: #0ebb50;
    border-radius: 50%;
    margin-top: 2px;


}





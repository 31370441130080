
.navbar {
    width: 100%;
    padding:30px 0;
    box-sizing: border-box;
    margin-bottom: 80px;
}

.navbar ul {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: flex-end;
}

.navbar .logo {
    font-weight: bold;
    color: var(--heading-color);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-right: auto;
}

.navbar .logo img {
    margin-right: 10px;
    filter:invert(25%);
    width: 40px;
    margin-top: -8px;
}

.navbar a {
    color: #333;
    text-decoration: none;
    margin-right: 20px;
}